<template>
	<div class="reading_container">
		<div class="reading_info_contents">
			<div class="title">[logConfig] 로그설정</div>
			<div class="Board">
				<table class="Board_type1">
					<colgroup><col width="0">
						<col width="200">
						<col width="350">
						<col width="*">
					</colgroup>
					<tbody>
						<tr><td></td>
							<td class="level2">로그 정보</td>
							<td class="source">$store.state.logConfig</td>
							<td class="sample">{{$store.state.logConfig}}</td>
						</tr>
						<tr><td></td>
							<td class="level2">로그 설정 변경</td>
							<td class="source">setLogConfig(debug, useTag)</td>
							<td class="sample">
								debug : <select v-model="debug"><option value="1">true</option><option value="0">false</option></select><br/>
								useTag : <input type="text" v-model="useTag"/><br/>
								<a href="javascript:void(0)" @click="setLogConfig(debug, useTag)">로그설정 변경</a>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">테스트 콘솔로그</td>
							<td class="source">
								console.debug(tag, msg, ...)<br/>
								console.log(tag, msg, ...)<br/>
								console.info(tag, msg, ...)<br/>
								console.warn(tag, msg, ...)<br/>
								console.error(tag, msg, ...)
							</td>
							<td class="sample">
								tag : <input type="text" v-model="tag"/><br/>
								test : <input type="text" v-model="test"/><br/>
								<a href="javascript:void(0)" @click="consoleLog('debug')">debug</a> | 
								<a href="javascript:void(0)" @click="consoleLog('log')">log</a> | 
								<a href="javascript:void(0)" @click="consoleLog('info')">info</a> | 
								<a href="javascript:void(0)" @click="consoleLog('warn')">warn</a> | 
								<a href="javascript:void(0)" @click="consoleLog('error')">error</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'logGuide',
	data() {
		return {
			debug : this.$store.state.logConfig.debug ? 1 : 0,
			useTag : this.$store.state.logConfig.useTag,
			tag : 'test',
			test : 'this is testlog'
		};
	},
	methods: {
		setLogConfig(debug, useTag){
			//console.log(debug, useTag, typeof debug, typeof useTag);
			debug = debug == 1;
			if(typeof useTag == 'string'){
				if(useTag.toLowerCase() == 'true') useTag = true;
				else if(useTag.toLowerCase() == 'false') useTag = false;
				else useTag = useTag.split(",");
			}
			if(typeof window.setLogConfig == 'function'){
				window.setLogConfig(debug, useTag);
			}
		},

		consoleLog(type){
			if(typeof console[type] == 'function'){
				console[type](this.log.tag, '[console.' + type + ']', this.log.test);
			}
		},
	},
};
</script>